
export const standardSsrRoutes: string[] = [
  '',
  '/',
  '/b/*',
  '/e/*',
  '/festival/*',
  '/news/*', '/hirfolyam/*',
  '/discover/*', '/felfedezo/*',
  '/data-deletion',
  '/cookies-policy',
  '/egyfeszt-foglalasi-fizetesi-feltetelek',
  '/terms-and-conditions',
  '/szerzodesi-feltetelek',
  '/altalanos-szerzodesi-feltetelek',
  '/privacy-policy',
  '/adatvedelmi-nyilatkozat',
  '/privacy-policy-hu',
  '/business-terms-and-conditions',
  '/partner-szerzodesi-feltetelek',
  '/in-time-uzleti-szerzodesi-feltetelek',
];

export const ticketSsrRoutes: string[] = [
  '',
  '/',
  '/*',
  '/data-deletion',
  '/cookies-policy',
  '/egyfeszt-foglalasi-fizetesi-feltetelek',
  '/terms-and-conditions',
  '/szerzodesi-feltetelek',
  '/altalanos-szerzodesi-feltetelek',
  '/privacy-policy',
  '/adatvedelmi-nyilatkozat',
  '/privacy-policy-hu',
  '/business-terms-and-conditions',
  '/partner-szerzodesi-feltetelek',
  '/in-time-uzleti-szerzodesi-feltetelek',
];

export const siofokSsrRoutes: string[] = [
  '/b/*',
  '/e/*',
  '/data-deletion',
  '/cookies-policy',
  '/egyfeszt-foglalasi-fizetesi-feltetelek',
  '/terms-and-conditions',
  '/szerzodesi-feltetelek',
  '/altalanos-szerzodesi-feltetelek',
  '/privacy-policy',
  '/adatvedelmi-nyilatkozat',
  '/privacy-policy-hu',
  '/business-terms-and-conditions',
  '/partner-szerzodesi-feltetelek',
  '/in-time-uzleti-szerzodesi-feltetelek',
];
