import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/components';

const commonRoutes: Routes = [
  {
    path: '404',
    component: PageNotFoundComponent,
  },
  {
    path: 'payment-complete',
    loadChildren: () => import('./pages/payment-status/payment-complete/payment-complete.module').then(res => res.PaymentCompleteModule),
  },
  {
    path: 'payment-canceled',
    loadChildren: () => import('./pages/payment-status/payment-canceled/payment-canceled.module').then(res => res.PaymentCanceledModule),
  },
  {
    path: 'payment-failed',
    loadChildren: () => import('./pages/payment-status/payment-failed/payment-failed.module').then(res => res.PaymentFailedModule),
  },
  {
    path: 'data-deletion',
    loadChildren: () => import('./pages/data-deletion/data-deletion.module').then(res => res.DataDeletionModule),
  },
  {
    path: 'cookies-policy',
    loadChildren: () => import('./pages/cookies-policy/cookies-policy.module').then(res => res.CookiesPolicyModule),
  },
  {
    path: 'egyfeszt-foglalasi-fizetesi-feltetelek',
    loadChildren: () => import('./pages/payment-terms/payment-terms.module').then(res => res.PaymentTermsModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then(res => res.TermsAndConditionsModule),
  },
  {
    path: 'szerzodesi-feltetelek',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then(res => res.TermsAndConditionsModule),
  },
  {
    path: 'altalanos-szerzodesi-feltetelek',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then(res => res.TermsAndConditionsModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(res => res.PrivacyPolicyModule),
  },
  {
    path: 'adatvedelmi-nyilatkozat',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(res => res.PrivacyPolicyModule),
  },
  {
    path: 'privacy-policy-hu',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(res => res.PrivacyPolicyModule),
  },
  {
    path: 'business-terms-and-conditions',
    loadChildren: () => import('./pages/business-terms-and-conditions/business-terms-and-conditions.module').then(res => res.BusinessTermsAndConditionsModule),
  },
  {
    path: 'partner-szerzodesi-feltetelek',
    loadChildren: () => import('./pages/business-terms-and-conditions/business-terms-and-conditions.module').then(res => res.BusinessTermsAndConditionsModule),
  },
  {
    path: 'in-time-uzleti-szerzodesi-feltetelek',
    loadChildren: () => import('./pages/business-terms-and-conditions/business-terms-and-conditions.module').then(res => res.BusinessTermsAndConditionsModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(res => res.AccountModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(res => res.LoginModule),
  },
  {
    path: 'my-tickets',
    loadChildren: () => import('./pages/my-tickets/my-tickets.module').then(res => res.MyTicketsModule),
  },
];


export const standardRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home-page/home-page.module').then(res => res.HomePageModule),
  },
  {
    path: 'b/:business-route',
    loadChildren: () => import('./pages/legacy-business-profile/business-profile.module').then(res => res.BusinessProfileModule),
  },
  {
    path: 'e/:event-route',
    loadChildren: () => import('./pages/event-profile/event-profile.module').then(res => res.EventProfileModule),
  },
  {
    path: 'artist/:artist',
    loadChildren: () => import('./pages/artist-page/artist-page.module').then(res => res.ArtistPageModule),
  },
  {
    path: 'brand/:brand',
    loadChildren: () => import('./pages/brand-page/brand-page.module').then(res => res.BrandPageModule),
  },
  {
    path: 'organizer/:organizer',
    loadChildren: () => import('./pages/organizer-page/organizer-page.module').then(res => res.OrganizerPageModule),
  },
  {
    path: 'festival/:festival',
    loadChildren: () => import('./pages/legacy-discover-page/discover-page.module').then(res => res.DiscoverPageModule),
    data: { festivalPage: true }
  },
  {
    path: 'discover/:region',
    loadChildren: () => import('./pages/legacy-discover-page/discover-page.module').then(res => res.DiscoverPageModule),
  },
  {
    path: 'felfedezo/:region',
    loadChildren: () => import('./pages/legacy-discover-page/discover-page.module').then(res => res.DiscoverPageModule),
  },
  {
    path: 'news/:region',
    loadChildren: () => import('./pages/news-feed/news-feed.module').then(res => res.NewsFeedModule),
  },
  {
    path: 'hirfolyam/:region',
    loadChildren: () => import('./pages/news-feed/news-feed.module').then(res => res.NewsFeedModule),
  },
  {
    path: 'invite/:inviteCode',
    loadChildren: () => import('./pages/invite/invite.module').then(res => res.InviteClientModule),
  },
  {
    path: 'tax-form',
    loadChildren: () => import('./pages/tax-form/tax-form.module').then(res => res.TaxFormModule),
  },
  {
    path: 'join/:business-route',
    redirectTo: 'b/:business-route'
  },
  ...commonRoutes,
  {
    path: ':region',
    loadChildren: () => import('./pages/discover-page/discover-page.module').then(res => res.DiscoverPageModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

export const ticketRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home-page/home-page.module').then(res => res.HomePageModule),
  },
  ...commonRoutes,
  {
    path: ':event-route',
    loadChildren: () => import('./pages/event-profile/event-profile.module').then(res => res.EventProfileModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

export const siofokRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/discover-page/discover-page.module').then(res => res.DiscoverPageModule),
    data: { region: 'siofok' }
  },
  {
    path: 'b/:business-route',
    loadChildren: () => import('./pages/legacy-business-profile/business-profile.module').then(res => res.BusinessProfileModule),
  },
  {
    path: 'e/:event-route',
    loadChildren: () => import('./pages/event-profile/event-profile.module').then(res => res.EventProfileModule),
  },
  ...commonRoutes,
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
